import { ThemeOptions } from "@mui/material";
import { createBoilerplateTheme } from "@gisce/oficina-virtual-components";

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions = {
  palette: {
    primary: {
      main: "#8cb053",
    },
    secondary: {
      main: "#8cb053",
    }
  },
  custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
    }
  }
};

export default createBoilerplateTheme(customConstants);
